<template>
	<v-bottom-navigation grow app class="d-flex justify-between align-center">
		<template v-if="authenticated">
			<v-btn text small plain :to="{ name: 'NewsfeedPosts' }" v-if="setupInfo.studies.verifiedStudies">
				<span>{{ $t('nav.newsfeed') }}</span>
				<v-icon>mdi-rss</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Applications' }" v-if="setupInfo.studies.verifiedStudies">
				<span>{{ $t('nav.jobs') }}</span>
				<v-icon>mdi-briefcase</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Messages' }" v-if="setupInfo.studies.verifiedStudies">
				<span>{{ $t('nav.messages') }}</span>
				<v-icon>mdi-forum</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Courses' }" v-if="setupInfo.studies.verifiedOpenedStudies && setupInfo.accountType.student.value">
				<span>{{ $t('nav.courses') }}</span>
				<v-icon>mdi-school</v-icon>
			</v-btn>
		</template>

		<v-btn text small plain @click.stop="opened ? close() : open()">
			<span>{{ $t('nav.menu') }}</span>
			<v-icon>mdi-menu</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'BottomNavbar',
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
			opened: 'sidebar/opened',
		}),
	},
	methods: {
		...mapActions('sidebar', ['open', 'close']),
	},
}
</script>
